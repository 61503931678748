<template>
  <div class="account-detail mt10">
    <div class="content-overflow">
      <div class="left-div fixContent">
        <ModalBox id="BaseInfo" :title="$t('基本信息')">
          <a-form-model ref="ruleForm">
            <a-row :gutter="16">
              <a-col :span="6">
                <a-form-model-item :label="$t('编码')">
                  <a-input disabled v-model="formData.code" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t('名称')">
                  <a-input disabled v-model="formData.name" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t('上级')">
                  <a-input disabled v-model="formData.parent_name" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t('余额方向')">
                  <a-input
                    disabled
                    :value="balance_direction_mapping[formData.balance_direction]"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item :label="$t('核算维度')">
                  <span class="mr20" v-for="item in formData.dimension_list" :key="item.id">
                    {{ item.name }}
                  </span>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </ModalBox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
export default {
  name: 'accountDetail',
  data() {
    return {
      formData: {},
    }
  },
  computed: {
    ...mapState(['balance_direction_mapping']),
  },
  activated() {
    if (this.$route.query.id) {
      http({
        url: api.getAccountDetail,
        data: { id: this.$route.query.id },
      }).then((res) => {
        this.formData = res.result
      })
    }
  },
  methods: {},
}
</script>
